/* eslint-disable prettier/prettier */
import { createStore } from "vuex";

import { v4 as uuidv4 } from "uuid";
import { ourClasses } from "./ourClasses";

export default createStore({
  state: {
    menuElements: [
      {
        id: uuidv4(),
        href: "/",
        textValue: "Start",
      },
      {
        id: uuidv4(),
        href: "/subpages/aboutUs.html",
        textValue: "O Nas",
      },
      {
        id: uuidv4(),
        href: "https://wod.guru/kup-karnet/crossfitblackstar",
        textValue: "Zapisz się",
        IsExternalLink: true,
      },
      {
        id: uuidv4(),
        href: "/subpages/price-list.html",
        textValue: "Cennik",
      },
      {
        id: uuidv4(),
        href: "/subpages/contact.html",
        textValue: "Kontakt",
      },
    ],
    socialMedia: [
      {
        id: uuidv4(),
        href: "https://www.facebook.com/CrossBlackstar",
        textValue: "Facebook",
      },
      {
        id: uuidv4(),
        href: "https://www.instagram.com/crossfit_blackstar/",
        textValue: "Instagram",
      },
    ],
    isPhoneMenuOpen: false,
    IsFiframeTransform: false,
    ourClasses,
  },
  getters: {
    menuElements(state) {
      return state.menuElements;
    },
    socialMedia(state) {
      return state.socialMedia;
    },
    isPhoneMenuOpen(state) {
      return state.isPhoneMenuOpen;
    },
    IsFiframeTransform(state) {
      return state.IsFiframeTransform;
    },
  },
  mutations: {
    openClosePhoneMenu(state) {
      const phoneMenu = document.querySelector(".phone-menu");
      const burgerBtnElements = document.querySelectorAll(
        ".phone-header__btn-element"
      );

      if (state.isPhoneMenuOpen && document.body.offsetWidth < 768) {
        phoneMenu.classList.remove("animate-openPhoneMenu");
        phoneMenu.classList.add("animate-closePhoneMenu");

        burgerBtnElements[0].classList.add("animate-unAnimateBurgerElement1");
        burgerBtnElements[0].classList.remove("animate-burgerElement1");

        burgerBtnElements[2].classList.add("animate-unAnimateBurgerElement3");
        burgerBtnElements[2].classList.remove("animate-burgerElement3");

        setTimeout(() => {
          burgerBtnElements[1].classList.add("opacity-1");
          burgerBtnElements[1].classList.remove("opacity-0");
        }, 125);

        setTimeout(() => {
          phoneMenu.classList.remove("block");
        }, 500);
        state.isPhoneMenuOpen = false;
      } else if (document.body.offsetWidth < 768) {
        phoneMenu.classList.remove("animate-closePhoneMenu");
        phoneMenu.classList.add("block");
        phoneMenu.classList.add("animate-openPhoneMenu");

        burgerBtnElements[0].classList.add("animate-burgerElement1");
        burgerBtnElements[0].classList.remove(
          "animate-unAnimateBurgerElement1"
        );

        burgerBtnElements[2].classList.add("animate-burgerElement3");
        burgerBtnElements[2].classList.remove(
          "animate-unAnimateBurgerElement3"
        );

        setTimeout(() => {
          burgerBtnElements[1].classList.add("opacity-0");
          burgerBtnElements[1].classList.remove("opacity-1");
        }, 125);

        state.isPhoneMenuOpen = true;
      }
    },
    setLocalStorage(_, item) {
      localStorage.setItem(item.name, item.value);
    },
  },
  actions: {
    setLocalStorage(ctx, item) {
      ctx.commit("setLocalStorage", item);
    },
  },
  modules: {},
});
