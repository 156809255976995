<template>
  <footer class="footer">
    <p class="footer__paragraph">
      <text-links classes="footer__link" href="mailto:info@cfblackstar.com"
        >info@cfblackstar.com</text-links
      >,
      <span class="inline-block">
        <text-links classes="footer__link" href="tel:508217126"
          >Tel.: 508 217 126</text-links
        >,
      </span>
      <span class="inline-block">
        <text-links
          classes="footer__link"
          href="/subpages/privacy-policy.html"
          :IsRouterLink="true"
        >
          &nbsp;Polityka Prywatności</text-links
        >,
        <text-links
          classes="footer__link"
          href="/subpages/contact.html"
          :IsRouterLink="true"
          >Kontakt</text-links
        >,
      </span>
      <span class="inline-block">
        &nbsp;Wykonanie:
        <text-links
          classes="footer__link"
          href="https://pk-program.pl"
          :IsExternalLink="true"
        >
          PK Program</text-links
        >,&nbsp;</span
      ><span class="inline-block">© 2022</span>
    </p>
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  @apply bg-3A text-white p-5 text-center;

  &__link {
    @apply text-white no-underline;
  }
}
</style>
