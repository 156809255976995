<template>
  <li :class="liClass">
    <a :href="href" :class="linkClass" target="_blank" rel="noopener noreferrer">
      <img
        v-if="textValue === 'Facebook'"
        src="../../../assets/img/facebook-app-logo.png"
        alt="Facebook Logo"
        :class="imgClass"
      />
      <img
        v-else
        src="../../../assets/img/instagram-icon.png"
        alt="Instagram Logo"
        :class="imgClass"
      />
      {{ textValue }}
    </a>
  </li>
</template>

<script>
export default {
  props: {
    IsPhoneSocialList: Boolean,
    href: String,
    textValue: String,
    liClass: String,
    imgClass: String,
    linkClass: String,
  },
};
</script>

<style lang="scss" scoped>
.header {
  &__social-element {
    @apply p-2 border-b border-dashed border-black md:hover:opacity-75;

    &:last-child {
      @apply border-none;
    }
  }
  &__link {
    &--social {
      @apply flex content-center items-center gap-1 text-sm;
    }
  }
  &__img {
    width: 34px;
  }
}

.phone-menu {
  &__social-element {
    @apply flex justify-center pl-2 pr-2 pt-1 pb-1 border-b rounded-lg border-3A;
  }

  &__link {
    &--social {
      @apply flex items-center gap-2;
      &:last-child {
        @apply gap-1;
      }
    }
  }

  &__img {
    max-height: 34px;
  }
}
</style>
