/*eslint-disable  prettier/prettier*/

const Start = () =>
  import ("../views/Start.vue")

const AboutUs = () =>
  import ("../views/AboutUs.vue");

const Contact = () =>
  import ("../views/Contact.vue");

const PrivacyPolicy = () =>
  import ("../views/PrivacyPolicy.vue");

const PriceList = () =>
  import ("../views/PriceList.vue");

const CatchAll = () =>
  import ("../views/CatchAll.vue");


const routes = [{
    path: "/index.html",
    component: Start,
    alias: "/",
    meta: {
            publicRoute: true,
            title: "CrossFit Black Star - Jeden z największych klubów crossfit we Wrocławiu",
            metaTags: [{
                    name: 'description',
                    content: 'Chcesz zacząć uprawiać sport lub jesteś już sportowcem i szukasz czegoś nowego? Crossfit to coś dla ciebie! Umów się na trening wprowadzajcy fundamentals i zacznij trenować razem z CrossFit Black Star'
                },
                {
                    property: 'keywords',
                    content: 'crossfit, black, star, crossfitblackstar, CrossFit Black Star, crossfitblackstar.pl, crossfitblackstar.com.pl, trening, treningi, fundamentals, crossfit dla początkujących, klub crossfit, klub crossfit wrocław, klub crossfit wroclaw, sport, sportowe, sportowy'
                },
                {
                    property: 'og:title',
                    content: 'CrossFit Black Star - Jeden z największych klubów crossfit we Wrocławiu'
                },
                {
                    property: 'og:description',
                    content: 'Chcesz zacząć uprawiać sport lub jesteś już sportowcem i szukasz czegoś nowego? Crossfit to coś dla ciebie! Umów się na trening wprowadzajcy fundamentals i zacznij trenować razem z CrossFit Black Star'
                },
                {
                    property: 'og:site_name',
                    content: 'Start'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://crossfitblackstar.com.pl/'
                },
                {
                    property: 'og:image',
                    content: 'https://crossfitblackstar.com.pl/img/logo.7bc2b995.png'
                },
            ]
        }
    },
    {
        path: "/subpages/aboutUs.html",
        component: AboutUs,
        meta: {
            publicRoute: true,
            title: "O Nas | CrossFit Black Star",
            metaTags: [{
                    name: 'description',
                    content: 'CrossFit Black Star, workouty, treningi crossfit dla dzieci, treningi ogólno-rozwojowe'
                },
                {
                    property: 'keywords',
                    content: 'crossfit, black, star, crossfitblackstar, CrossFit Black Star, crossfitblackstar.pl, crossfitblackstar.com.pl, trening, treningi, crossfit dla początkujących, klub crossfit, klub crossfit wrocław, klub crossfit wroclaw'
                },
                {
                    property: 'og:title',
                    content: 'O Nas | CrossFit Black Star'
                },
                {
                    property: 'og:description',
                    content: 'CrossFit Black Star, workouty, treningi crossfit dla dzieci, treningi ogólno-rozwojowe'
                },
                {
                    property: 'og:site_name',
                    content: 'O Nas'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://crossfitblackstar.com.pl/subpages/aboutUs.html'
                },
                {
                    property: 'og:image',
                    content: 'https://crossfitblackstar.com.pl/img/logo.7bc2b995.png'
                },
            ]
        }
    },
    {
        path: "/subpages/contact.html",
        component: Contact,
        meta: {
            publicRoute: true,
            title: "Kontakt | CrossFit Black Star",
            metaTags: [{
                    name: 'description',
                    content: 'Chcesz rozpocząć przygodę z crossfitem lub masz jakieś pytania? Skontaktuj się z nami.'
                },
                {
                    property: 'keywords',
                    content: 'crossfit, black, star, crossfitblackstar, CrossFit Black Star, crossfitblackstar.pl, crossfitblackstar.com.pl, kontakt do klubu crossfit, kontakt do crossfit black star, klub crossfit, klub crossfit wrocław, klub crossfit wroclaw'
                },
                {
                    property: 'og:title',
                    content: 'Kontakt | CrossFit Black Star'
                },
                {
                    property: 'og:description',
                    content: 'Chcesz rozpocząć przygodę z crossfitem lub masz jakieś pytania? Skontaktuj się z nami.'
                },
                {
                    property: 'og:site_name',
                    content: 'Kontakt'
                },
                {
                    property: 'og:type',
                    content: 'website'
                },
                {
                    property: 'og:url',
                    content: 'https://crossfitblackstar.com.pl/subpages/contact.html'
                },
                {
                    property: 'og:image',
                    content: 'https://crossfitblackstar.com.pl/img/logo.7bc2b995.png'
                },
            ]
        }
    },
    {
        path: "/subpages/privacy-policy.html",
        component: PrivacyPolicy,
        meta: {
            publicRoute: false,
            title: "Polityka Prywatności | CrossFit Black Star",
            metaTags: [{
                name: "description",
                content: "Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies."
            },
                {
                    property: "keywords",
                    content: "crossfit, black, star, crossfitblackstar, CrossFit Black Star, crossfitblackstar.pl, crossfitblackstar.com.pl, klubu crossfit polityka prywatności, crossfit black star polityka prywatności, klub crossfit, klub crossfit wrocław, klub crossfit wroclaw"
                },
                {
                    property: 'og:title',
                    content: 'Polityka Prywatności | CrossFit Black Star'
                },
                {
                    property: 'og:description',
                    content: 'Polityka prywatności opisuje zasady przetwarzania przez nas informacji na Twój temat, w tym danych osobowych oraz ciasteczek, czyli tzw. cookies.'
                },
                {
                    property: "og:site_name",
                    content: "Polityka Prywatności"
                },
                {
                    property: "og:type",
                    content: "website"
                },
                {
                    property: "og:url",
                    content: "https://crossfitblackstar.com.pl/subpages/privacy-policy.html"
                },
                {
                    property: "og:image",
                    content: "https://crossfitblackstar.com.pl/img/logo.7bc2b995.png"
                }
            ]
        }
    },
    {
        path: "/subpages/price-list.html",
        component: PriceList,
        meta: {
            publicRoute: true,
            title: "Cennik | CrossFit Black Star",
            metaTags: [{
                name: "description",
                content: "Chcesz zapisać się na nasze zajęcia. Tutaj znajdziesz nasze cenny oraz grafik zajęć. Zapisz się i widzimy się w naszym klubie."
            },
                {
                    property: "keywords",
                    content: "crossfit, black, star, crossfitblackstar, CrossFit Black Star, crossfitblackstar.pl, crossfitblackstar.com.pl, klubu crossfit cennik, crossfit black star cennik, klub crossfit, klub crossfit wrocław, klub crossfit wroclaw"
                },
                {
                    property: "og:title",
                    content: "Cennik | CrossFit Black Star"
                },
                {
                    property: "og:description",
                    content: "Chcesz zapisać się na nasze zajęcia. Tutaj znajdziesz nasze cenny oraz grafik zajęć. Zapisz się i widzimy się w naszym klubie."
                },
                {
                    property: "og:site_name",
                    content: "Cennik"
                },
                {
                    property: "og:type",
                    content: "website"
                },
                {
                    property: "og:url",
                    content: "https://crossfitblackstar.com.pl/subpages/price-list.html"
                },
                {
                    property: "og:image",
                    content: "https://crossfitblackstar.com.pl/img/logo.7bc2b995.png"
                }
            ]
        }
    },
    { path: "/:catchAll(.*)", component: CatchAll }
];

export default routes;