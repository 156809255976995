<template>
  <header v-if="type === 'normal'" class="header">
    <router-link to="/">
      <img
        class="header__logo"
        src="../../../assets/img/webp/logo.webp"
        alt="CrossFit Black Star"
      />
    </router-link>
    <ul class="header__menu-list">
      <menu-element
        v-for="element in menuElements"
        :key="element.id"
        :href="element.href"
        :text-value="element.textValue"
        :IsExternalLink="element.IsExternalLink"
        classes="header__menu-element"
      ></menu-element>
      <li class="header__menu-element" @click="appearHidenSocialList">
        <button class="header__social-link">Social Media</button>
      </li>
      <ul class="header__social-list" ref="socialList">
        <social-media-element
          v-for="socialLink in socialMedia"
          :key="socialLink.id"
          :IsPhoneSocialList="false"
          :href="socialLink.href"
          :textValue="socialLink.textValue"
          liClass="header__social-element"
          imgClass="header__img header__img--social"
          linkClass="header__link header__link--social"
        ></social-media-element>
      </ul>
    </ul>
  </header>
  <header v-else class="phone-header">
    <router-link to="/">
      <img
        class="phone-header__logo"
        src="../../../assets/img/webp/logo.webp"
        alt="CrossFit Black Star"
      />
    </router-link>
    <button class="phone-header__btn" @click="openClosePhoneMenu">
      <div class="bg-black phone-header__btn-element"></div>
      <div class="bg-black phone-header__btn-element"></div>
      <div class="bg-black phone-header__btn-element"></div>
    </button>
  </header>
  <ul v-if="type === 'phone'" class="phone-menu">
    <menu-element
      v-for="element in menuElements"
      :key="element.id"
      :href="element.href"
      :text-value="element.textValue"
      :IsExternalLink="element.IsExternalLink ? element.IsExternalLink : null"
      classes="phone-menu__element"
    ></menu-element>
    <li
      class="phone-menu__element phone-menu__element--last"
      @click="appearHidenPhoneSocialList"
    >
      <button class="phone-menu__social-link">Social Media</button>
      <ul class="phone-menu__social-list" ref="phoneSocialList">
        <social-media-element
          v-for="socialLink in socialMedia"
          :IsPhoneSocialList="true"
          :key="socialLink.id"
          :href="socialLink.href"
          :textValue="socialLink.textValue"
          liClass="phone-menu__social-element"
          imgClass="phone-menu__img phone-menu__img--social"
          linkClass="phone-menu__link phone-menu__link--social"
        ></social-media-element>
      </ul>
    </li>
  </ul>
</template>

<script>
/* eslint-disable for-direction */
/* eslint-disable prettier/prettier */
import MenuElement from "./MenuElement.vue";
import SocialMediaElement from "./SocialMediaElement.vue";

export default {
  components: {
    MenuElement,
    SocialMediaElement,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "normal",
    },
  },
  data() {
    return {
      menuElements: this.$store.getters.menuElements,
      socialMedia: this.$store.getters.socialMedia,
      isShowSocialList: false,
      isShowPhoneSocialList: false,
    };
  },

  methods: {
    appearHidenSocialList() {
      const socialList = this.$refs.socialList;

      if (this.isShowSocialList) {
        socialList.classList.remove("animate-appear");
        socialList.classList.add("animate-hidden");
        setTimeout(() => {
          socialList.classList.remove("grid");
        }, 300);
        this.isShowSocialList = false;
      } else {
        socialList.classList.remove("animate-hidden");
        socialList.classList.add("grid");
        socialList.classList.add("animate-appear");
        this.isShowSocialList = true;
      }
    },
    appearHidenPhoneSocialList() {
      const socialList = this.$refs.phoneSocialList;

      if (this.isShowPhoneSocialList) {
        socialList.classList.remove("animate-appear");
        socialList.classList.add("animate-hidden");
        setTimeout(() => {
          socialList.classList.remove("grid");
        }, 300);
        this.isShowPhoneSocialList = false;
      } else {
        socialList.classList.remove("animate-hidden");
        socialList.classList.add("grid");
        socialList.classList.add("animate-appear");
        this.isShowPhoneSocialList = true;
      }
    },
    openClosePhoneMenu() {
      this.$store.commit("openClosePhoneMenu");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  @apply h-28 hidden grid-flow-col items-center md:grid bg-white z-20 w-full relative z-30;

  @media (min-width: 1921px) {
    max-width: 1920px;
  }

  &__logo {
    margin-left: 15%;
    width: 100px;
  }

  &__menu-list {
    @apply flex h-min justify-end mr-4;
  }

  &__menu-element {
    @apply pt-0 pb-0 pl-3 pr-3 flex content-center md:hover:opacity-70 transition-opacity border-r border-solid border-black;
  }

  &__social-list {
    @apply hidden transform absolute bg-white opacity-0 border-solid border-black border rounded-xl rounded-tr-none rounded-tl-none;
    --tw-translate-y: 28%;
    width: 127px;
  }
}

.phone-header {
  @apply grid grid-flow-col grid-cols-3/4 fixed top-0 z-20 w-full md:hidden bg-white max-h-36 min-h-58.5 border-solid border-black border-b-2;

  &__logo {
    width: 80px;
    margin-left: 8%;
  }

  &__btn {
    @apply grid content-center h-full pb-2 justify-self-end min-w-32;

    &-element {
      @apply mt-2 border-solid rounded-full border-black border-1.5 justify-self-center w-1/2;
    }
  }
}

.phone-menu {
  @apply fixed right-0 bg-white h-full border-solid border-black border-l-2 transform translate-x-full hidden z-20;
  width: 163px;
  top: 5.12rem;

  &__element {
    @apply p-3 text-center border-t border-solid border-black;

    &:first-child {
      @apply border-0;
    }

    &--last {
      @apply border-b;
    }
  }

  &__social-list {
    @apply mt-3 hidden opacity-0;
  }
}
</style>
