import { v4 as uuidv4 } from "uuid";

export const ourClasses = [
  {
    id: uuidv4(),
    title: "ZAJĘCIA CROSSFIT KIDS",
    desc: `<b>CrossFit KIDS</b> to bezpieczna formuła zajęć sportowych dla dzieci nastawiona na efekt doskonałej sprawności fizycznej, koordynacji, integracji poprzez świetną zabawę dla Twojego dziecka!  Nasze zajęcia łączą w sobie nie tylko ćwiczenia fizyczne ale również przekazują wiedzę o działaniu młodego ciała. 
    <br />
    <br /> 
    Podczas zajęć uczymy się odpowiedniego ruchu naszego ciała ale również dowiadujemy się dlaczego i jak to działa. CrossFit to zajęcia umożliwiające poznanie sportu z zupełnie innej strony. <br /><br /> Dzięki systematycznym treningom i nauce możemy pracować od najmłodszych lat nad siłą, zręcznością, szybkością jak i odpowiednią postawą. <br /> Ponadto kluczowym czynnikiem naszych zajęć jest element edukacyjny. Program <b>CrossFit KIDS</b> zapewnia dzieciom w różnym wieku odpowiednią aktywność w zabawnym i wciągającym formacie. Doskonalimy umiejętności nie tylko fizyczne ale również życiowe takie jak wytrwałość, ciężka praca, odpowiedzialność osobista, akceptacja opinii innych czy przestrzeganie zasad. 
    <br />
    <br />
    Zapraszamy dzieci w przedziale wiekowym 7-11 lat. PIERWSZE ZAJĘCIA SĄ DARMOWE!`,
  },
  {
    id: uuidv4(),
    title: "ZAJĘCIA Z KALISTENIKI",
    desc: `Kalistenika Klub Nietoperzy <br />
    Zajęcia dla dorosłych z gimnastyki. Jeśli Twoim celem jest poprawa mobilności obręczy barkowej lub dolnych partii ciała, nauka Handstand, Muscle Up, Pull Over, Pull up , Dips, Push Up, itd., to te zajęcia są dla Ciebie ! 
    <br />
    <br />
    Otwarta grupa Klubu Nietoperzy przywita każdego bez względu na poziom wytrenowania. Dzięki licznym progresjom można zacząć chodzić na zajęcia w dowolnym momencie. Grupa podąża 3 miesięcznym cyklem treningowym wzmacniając siłę i sprawność pod elementy gimnastyczne wykorzystywane w fitnessie funkcjonalnym.`,
  },
  {
    id: uuidv4(),
    title: "TRENINGI PERSONALNE",
    desc: `<b>Trenuj jeden na jeden:</b> <br />
    Jeżeli chcesz zacząć trenować indywidualnie pod okiem naszych wykwalifikowanych trenerów to CrossFit Black Star jest takim miejscem. W naszej ofercie znajdziesz treningi indywidualne, dla dwóch osób lub w małych 3-4 osobowych grupkach.
    <br />
    <br />
    Jest również możliwość skorzystania z treningów personalnych o specyfice bokserskiej. Dodatkowo w naszym klubie można umówić się na wizytę z fizjoterapeutą.
    <br />
    <br />
    <b>DLA KOGO:</b> <br /> 
    - trening personalny jest idealnym rozwiązaniem dla osób szukających bardzo indywidualnego podejścia do treningu i dopasowania do potrzeb fizycznych osoby,<br />
    - dla osób, które nie przepadają za zajęciami grupowymi, <br />
    - dla osób, które dopiero zaczynają swoją przygodę z ruchem i bezpieczniej czują się kiedy cała uwaga trenerska jest skierowana tylko na nich,<br />
    - dla sportowców rozmaitych dyscyplin poszukujących takiego programowania do przygotowania motorycznego, które uwzględni specyfikę ich sportu (mamy doświadczenie z prowadzenia zawodników między innymi sztuk walki, pływaków oraz futbolistów amerykańskich).`,
  },
  {
    id: uuidv4(),
    title: "ZAJĘCIA WOD",
    desc: `Zajęcia regularne <b>WOD</b> są przeznaczone dla osób, które opanowały podstawową technikę CrossFitową. Treningi programowane tak, aby każdy dzień był wyzwaniem, przy jednoczesnym rozwoju wszystkich cech motorycznych. <b>WOD</b> (Workout of the Day) oznacza Trening Dnia. Dzięki codziennej zmienności treningu klubowicze nie tylko stale poszerzają swoje umiejętności, ale także dobrze się bawią! Tu nie ma miejsca na nudę!`,
  },
  {
    id: uuidv4(),
    title: "MOBILITY",
    desc: `Zajęcia stworzone w celu rozwiązywania problemów bólowych, zapobiegania kontuzjom, polepszenia mobilności – ruchomości – elastyczności mięśni, ścięgien, więzadeł i stawów, zwiększenia możliwości ciała w życiu codziennym.
    <br />
    <br />
    Na zajęciach używamy wszelkiego rodzaju przyrządów. Poprawiamy Twoją mobilność przy pomocy <b>gum, rollerów, taśm, piłek Lacrosse</b> i oczywiście <b>własnego ciała</b>.
`,
  },
  {
    id: uuidv4(),
    title: "WEIGHTLIFTING",
    desc: `Zajęcia z podnoszenia ciężarów to doskonałe uzupełnienie zajęć Crossfitowych, a także pierwszorzędny element w  przygotowaniu motorycznym w każdej dyscyplinie sportowej: koszykówka, siatkówka, MMA, Judo itd. Zajęcia prowadzone przez trenera od podnoszenia ciężarów.
    <br />
    <br />
    •\tNauka podstawowych ruchów w podnoszeniu ciężarów, <br />
    •\tPraca nad techniką i poprawnym wykonaniem poszczególnych elementów, <br />
    •\tProgram treningowy ukierunkowany na progres ciężarowy, <br />
    •\tZajęcia dla: poczatkujących / średnio-zaawansowanych, <br />
    •\tZajęcia: dla Pań i Panów,`,
  },
  {
    id: uuidv4(),
    title: "GIMNASTYKA",
    desc: `Zajęcia  gimnastyczne w oparciu o ćwiczenia  z ciężarem własnego ciała oraz na wybranych przyrządach gimnastycznych m.in.: drążek, koła gimnastyczne. 
    <br />
    <br />
    Zajęcia ukierunkowane są na wszechstronny rozwój fizyczny oraz poprawę podstawowych wzorców ruchowy. Na zajęciach budujemy mocne fundamenty pod zaawansowane ćwiczenia gimnastyczne (Bar Muscle Up/Ring Muscle Up) występujące m.in., w Crossficie.
`,
  },
];
