<template>
  <button class="btn-top" @click="scrollToTop">
    <div class="btn-top__element"></div>
    <div class="btn-top__element"></div>
  </button>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollBy(0, -1 * window.pageYOffset);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-top {
  @apply fixed bottom-4 right-4 w-14 h-12 rounded-xl md:hover:opacity-75 transition-opacity z-30 hidden;
  background-color: rgb(229, 231, 235);

  &__element {
    @apply bg-3A border border-3A transform w-5;
    margin-left: 8.5px;
    --tw-translate-x: 3.5px;

    &:first-child {
      @apply rotate-45;
      --tw-translate-x: 0.9rem;
      --tw-translate-y: 1.5px;
    }
    &:last-child {
      @apply -rotate-45 w-4;
      margin-bottom: 5px;
      --tw-translate-y: 1px;
    }
  }
}
</style>
